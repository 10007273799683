@import "../../../styles/base";

.listItemCalendarTicket {
  @include ticketShape;
}

.descriptionText {
  @include descriptionP;
}

.detailsLink {
  @include detailsLink;
}

.textAbove {
  font-size: 1.3rem;
  margin: 0;
}

.persuasionText {
  color: $green;
  font-weight: bold;
    font-size: $font-m;
}

.persuasionRibbon {
  position: absolute;
  padding: 5px 40px;
  bottom: 100%;
  left: 0;
  background: $orange-dark;
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: $border-radius $border-radius 0 0;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  @include fromTablet {
    flex-direction: row;
    flex-wrap: wrap;
  }

  & + .row {
    border-top: solid #ddd 1px;
    padding-top: 10px;
  }
}

.ticketProductFullWidth {
  @include ticketProductFullWidth;
}

.footer {
  @include ticketFooter(fullWidth);
}

.modalLink {
  @include modalLink;
}

.btn {
  @include btn;
}

.footerDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  @include buttonHolder;
}

.logosHolder {
  @include fromTablet {
    flex-basis: calc(40% - 1rem);
  }

  @include fromDesktop {
    flex-basis: calc(50% - 1rem);
  }
}

.logos {
  display: flex;
}
